
import { Component, Vue } from 'vue-property-decorator';
import VWrapper from '../../components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';

const venue = namespace('venue');

@Component({
  components: { VWrapper },
})
export default class VenueVerification extends Vue {
  @venue.State('active') public venue!: Venue;

  @venue.Action('show') public show: any;
  @venue.Action('publish') public publish: any;

  get id() {
    return this.$route.params.id;
  }

  public mounted(): void {
    this.$startLoading('venue');
    this.show({ id: this.id }).finally(() => {
      this.$stopLoading('venue');
    });
  }

  public submit() {
    this.publish({ id: this.venue._id }).then(() => {
      this.$router.push({ name: 'venue.index' });
    });
  }

  public created() {
    if (!this.venue) {
      this.$startLoading('venue');
    }
  }
}
